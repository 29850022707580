.ingress {
  font-size: 16px;
  line-height: 1.66;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0;

  /* Handle margin-top of next adjacent element against this p element */
  &+p,
  &+ul,
  &+ol,
  &+pre,
  &+div,
  &+h1,
  &+h2,
  &+h3,
  &+h4,
  &+h5,
  &+h6 {
    margin-top: 24px;
  }
}